@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Samurai";
    font-weight: 400;
    src: url(./assets/CfSamuraiBobRegular-rmG8.ttf);
  }

  body {
    font-family: "Montserrat", sans-serif;
  }
}

@layer components {
  .samurai {
    font-family: "Samurai", sans-serif;
  }

  #blossom_container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .petal {
    background: url(http://talktofill.surge.sh/cherry-blossom.png) no-repeat;
  }
  .petal.petal-style1 {
    width: 45px;
    height: 20px;
    background-position: -31px 0;
  }
  .petal.petal-style2 {
    width: 42px;
    height: 22px;
    background-position: 0 -23px;
  }
  .petal.petal-style3 {
    width: 37px;
    height: 24px;
    background-position: 0 -50px;
  }
  .petal.petal-style4 {
    width: 26px;
    height: 34px;
    background-position: -49px -35px;
  }
}
